@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,600;1,400&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.particle {
  position: absolute;
  will-change: transform;
}

.custom-textarea {
  resize: none;
  border: 2px solid rgb(76, 76, 126);
  border-radius: 0.6rem;
  background: transparent;
  font-family: inherit;
  color: white;
  font-size: 1rem;
  padding: 0.4rem;
}

.box {
  width: 60%;
  aspect-ratio: 1/1;
}

.blob-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

svg {
  width: 100%;
  aspect-ratio: 1/1;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 2rem;
}

.swiper-pagination-bullet {
  width: 1rem !important;
  height: 1rem !important;
  background: #dc7420 !important;
  opacity: 0.5 !important;
}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
}